import './privacy.css';

function PomfretPrivacy() {
    return (
      <>
      <h1>Pomfret School Digital ID Card- Privacy Policy</h1>
      <p>Last Updated: January 10, 2022</p>
      <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
      <p>We are required to collect some of your personal data to operate the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. </p> 
      <h3>Operation of the Service</h3>
      <p>The Service is owned and operated by Stockheld Technologies, the Company, for the sole use by the community members of Pomfret School, Inc, the Client. The Company offers and operates this service for the Client on their behalf, however the rights to the Service and all other systems associated with the Service are the sole intellectual property of the Company.</p>
      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p> 
      <h3>Definitions</h3>
      <p>For the Purposes of this privacy policy:</p>
      <ul className="privacy-definitions-list">
          <li>Account - means a unique account created for You to access our Service or parts of our Service.</li>
          <li>Company - (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Stockheld Technologies, a sole proprietorship ran by Rishi Sura-Collins, CEO.</li>
          <li>Client - (referred to as either “the Client”, or “Client” in this Agreement) refers to Pomfret School, Inc, a private education institution incorporated at 398 Pomfret Street, Pomfret, Connecticut, 06258, United States.</li>
          <li>Cookies - are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</li>
          <li>Country - refers to: Connecticut, United States</li>
          <li>Device - means any device that can access the Service such as a computer, a cell phone or a digital tablet.</li>
          <li>Personal Data - is any attribute that relates to an identified or identifiable individual.</li>
          <li>Service - refers to the website.</li>
          <li>Service Provider - means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</li>
          <li>Usage Data - refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
          <li>Website - refers to Digital ID Card Service- Pomfret School</li>   
          <li>You - means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>   
      </ul>

        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3> 
        <h4>Personal Data</h4>
        <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>

        <ul className="privacy-external-info">
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Internal Identification Information (Student ID Numbers)</li>
            <li>Usage and analytics data</li>
        </ul>

        <h4>Usage Data</h4>
        <p>Usage Data is collected automatically when using the Service.</p>

        <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
        <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>

        <h4>Tracking Technologies and Cookies</h4>
        <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>

        <ul>
            <li>Cookies or Browser Cookies. A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</li>
            <li>Login Tokens. In order to maintain security and the integrity of the Service, login tokens, or cookies are stored on your device to record your session activity, and keep you logged into the Service. These cookies are for your own security and protection, and because of this, they expire frequently. When these cookies expire, you may need to reauthenticate. Logging out of the Service manually using the Logout button also purges your login data and session information from your device. Cookies are stored on the client device only (your device), and are not stored or logged within our servers.</li>
            <li>Web Beacons. Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
        </ul>

        <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can learn more about cookies here: Cookies by TermsFeed Generator.</p>

        <p>We use both session and persistent Cookies for the purposes set out below:</p>

        <ul>
            <li>Necessary / Essential Cookies</li>
            <p>Type: Session Cookies</p>
            <p>Administered by: Us</p>
            <p>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</p>

        </ul>

        <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>

        <h4>Use and Retention of Data</h4>
        <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        <p>Your information, including Personal Data, is processed on the Company's operating servers and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.</p>
        <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
        <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

        <h2>Privacy Rights for Minors (Under the age of 13- US Only)</h2>
        <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
        <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>

        <h2>Changes to this Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, You can contact us via email or using the contact options on this website.</p>

      </>
      
    );
  }

  export default PomfretPrivacy;