import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React from 'react';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {value: ''};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        alert('This form is deprecated and will be phased out and removed. Please use the contact button in the lower right corner, avaliable on any page on this site.' , 'ErrorReturnedValue:' + this.state.value);
        event.preventDefault();
        //add HTTP post request/submit data code in this function here. use axios.
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Label>Email address</Form.Label>
                    <Form.Control value={this.state.value} onChange={this.handleChange} type="text" placeholder="Enter Your Name." />
                            <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                            </Form.Text>
                </Form.Group>

                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
            </Form>
        )
    }
}

export default ContactForm;